import request from "@/utils/request";

export function getAllCategories() {
  return request(
    {
      url: "categories/get-categories",
      method: "get"
    }
  )
}

export function searchCategory(data){
    return request(
      {
        url: "/searchCategory",
        method: "post",
        data
      }
    )
}

export function addCategory(data){
  return request(
    {
      url: "/category",
      method: "post",
      data
    }
  )
}

export function getCategory(category_id) {
  return request(
    {
      url: `/category/${category_id}`,
      method: "get"
    }
  )
}

export function getCategoriesSort() {
  return request(
    {
      url: "/categories/sorts",
      method: "get"
    }
  )
}

export function editCategory(category_id,data) {
  return request(
    {
      url: `/category/${category_id}`,
      method: "put",
      data
    }
  )
}

export function toggleShowInHome(category_id) {
 return request(
   {
     url: `/categories/show-in-home/${category_id}`,
     method: 'get'
   }
 )
}

export function toggleShowInNav(category_id) {
 return request(
   {
     url: `/categories/show-in-nav/${category_id}`,
     method: 'get'
   }
 )
}

export function toggleIsActive(category_id) {
   return request(
     {
       url: `/categories/status/${category_id}`,
       method: 'get'
     }
   )
}


export function deleteCategory(category_id) {
  return request(
    {
      url: `/category/${category_id}`,
      method: 'delete'
    }
  )
}
