<template>

  <el-button type="primary" class="mb-1" @click="toggleFilter">
    <i class="las la-sliders-h ms-1" style="font-size: 1.5em"></i>
    <span> فلترة </span>
  </el-button>

  <transition name="slide">
    <template v-if="show">
      <slot></slot>
    </template>
  </transition>
</template>

<script setup>
import {ref} from "vue";

const show         = ref(false)
const toggleFilter = () => show.value = !show.value
</script>
