<template>
  <div class="container-fluid mt-3">
    <div class="d-flex align-items-sm-stretch align-items-end justify-content-between flex-wrap">
      <div class="d-flex flex-wrap align-items-lg-center  mb-1 flex-sm-row flex-column justify-content-between flex-grow-1">

        <Breadcrumb current_page="جميع التصنيفات"/>
        <router-link class="el-button el-button--success el-button--default btn-add ms-2"  to="/add-category">
          إضافة تصنيف جديد
        </router-link>
      </div>
      <collapse-filter>
        <form action="" class="filter-form flex-wrap d-flex gap-3">
          <div class="form-group d-flex gap-1 align-items-baseline">
            <label for="id" class="mb-0">بحث حسب ال id</label>
            <input type="text" v-model="id" class="form-control search_by_id" id="id"/>
          </div>

          <div class="form-group d-flex gap-1 align-items-baseline">
            <label for="name" class="mb-0">اسم التصنيف</label>
            <input type="text" v-model="category_name" class="form-control  " id="name"/>
          </div>



          <div class="form-group align-self-end ">
            <button class="btn btn-search px-3" type="button" @click="handleSearch" :disabled="loading">
              بحث
            </button>
          </div>
        </form>
      </collapse-filter>
    </div>


    <el-card  class=" mt-2 overflow-auto">
      <el-table id="top-table" :data="categories_list" class="mb-4" stripe style="width: 100%"
                max-height="850" v-loading="loading">
        <el-table-column prop="id" class-name="px-3" label="الترتيب" width="100"/>
        <el-table-column prop="name" width="200" class-name="px-3" label="التصنيف"/>
        <el-table-column  width="200" label="القسم">
          <template #default="scope">
            {{ scope.row.parent_id ? scope.row.parent.name : 'تصنيف رئيسي' }}
          </template>
        </el-table-column>


        <el-table-column prop="show_in_home" width="120" label="الرئيسية">
          <template #default="scope">
            <el-tooltip :content="scope.row.show_in_home === 1 ? 'إخفاء' : 'إظهار'" placement="top">
              <el-switch
                  v-model="scope.row.show_in_home"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  @change="handleShowInHomeChange(scope.row.id)"
              />
            </el-tooltip>
          </template>
        </el-table-column>


        <el-table-column prop="show_in_nav" width="130" label="شريط العناوين؟">
          <template #default="scope">
            <el-tooltip :content="scope.row.show_in_nav === 1 ? 'إخفاء' : 'إظهار'" placement="top">
              <el-switch
                  v-model="scope.row.show_in_nav"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  @change="handleShowInNavChange(scope.row.id)"
              />
            </el-tooltip>
          </template>
        </el-table-column>


        <el-table-column label="الأدوات" width="120" class-name="control-btns">
          <template #default="scope">

            <el-tooltip class="item" effect="dark" content="تعديل التصنيف" placement="top">
              <router-link :to="`/edit-category/${scope.row.id}`" class="py-1 px-2 btn btn-outline-primary">
                <i class="far fa-edit"/>
              </router-link>
            </el-tooltip>


            <el-popconfirm
                hide-icon
                confirmButtonType="danger"
                cancelButtonType="default"
                confirmButtonText="نعم"
                cancelButtonText="لا"
                iconColor="red"
                @confirm="handleIsActiveChange(scope.row.id)"
                :title="`هل أنت متأكد أنك تريد ${scope.row.is_active ? 'تعطيل' : 'تفعيل'} هذا التصنيف؟`">
              <template #reference>
                <el-button :title="`${scope.row.is_active ? 'تعطيل' : 'تفعيل'} التصنيف`" class="py-1 px-2 btn"
                  :class="scope.row.is_active ? 'btn-outline-warning' : 'btn-outline-secondary'"
                >
                  <i class="las la-ban" style="font-size: 1.25em;font-weight: bold"/>
                </el-button>
              </template>
            </el-popconfirm>

            <template v-if="!scope.row.is_used">
              <el-popconfirm
                  hide-icon
                  confirmButtonType="danger"
                  cancelButtonType="default"
                  confirmButtonText="نعم"
                  cancelButtonText="لا"
                  iconColor="red"
                  @confirm="confirmDelete(scope.row.id)"
                  title="هل أنت متأكد أنك تريد حذف هذا التصنيف؟">
                <template #reference>
                  <el-button title="حذف التصنيف" class="py-1 px-2 btn btn-outline-danger">
                    <i class="far fa-trash-alt"/>
                  </el-button>
                </template>
              </el-popconfirm>
            </template>


          </template>
        </el-table-column>
      </el-table>
    </el-card>


  </div>
</template>

<script>
import Loader from "@/components/Loader";
import {getAllCategories, searchCategory} from "@/services/categories";
import CollapseFilter from '../../components/CollapseFilter'
import Breadcrumb from '../../components/Breadcrumb'
import {deleteCategory, toggleIsActive, toggleShowInHome, toggleShowInNav} from "../../services/categories";
import {useToast} from "vue-toastification";

export default {
  name      : "Categories",
  components: {Loader, CollapseFilter, Breadcrumb},
  data()
  {
    return {
      toast: useToast(),
      id             : "",
      category_name  : "",
      categories_list: [],
      loading        : false
    }
  },
  mounted()
  {
    this.fetchAllCategories();
  },
  methods: {
    async handleShowInHomeChange(id){
      const result = await toggleShowInHome(id)
      if(result.status === 200){
        this.toast.success(result.data.success)
      }
    },
    async handleShowInNavChange(id){
      const result = await toggleShowInNav(id)
      if(result.status === 200){
        this.toast.success(result.data.success)
      }
    },
    async handleIsActiveChange(id){
      const result = await toggleIsActive(id)
      if(result.status === 200){
        let category = this.categories_list.find(category => category.id === id);
        category.is_active = !category.is_active
        this.toast.success(result.data.success)
      }
    },
    fetchAllCategories()
    {
      this.loading = true
      getAllCategories()
          .then((response) => {
            this.loading         = false
            this.categories_list = response.data
          }).catch(() => {
        this.loading = false
      })
    },
    handleSearch()
    {
      this.loading = true
      const data   = {
        category_name: this.category_name,
        id           : this.id
      }
      searchCategory(data)
          .then(res => {
            this.loading         = false
            this.categories_list = res.data
          })
          .catch(() => {
            this.loading = false
          })
    },

    async confirmDelete(id)
    {
        try {
          const result = await deleteCategory(id);
          console.log(result)
          if(result.status === 200){
            let categoryToDelete = this.categories_list.findIndex(category => category.id === id)
            this.categories_list.splice(categoryToDelete, 1);
            this.toast.success("تم الحذف بنجاح");
          }
        }catch (e)
        {
          this.toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى");
        }

    }
  }
}
</script>

<style lang="scss">
.filter-form {
  .form-group label {
    min-width: 100px;
  }
}

.first_col {
  width: 100px;
}

.control-btns {
  display: flex;
  justify-content: center;
  gap: 8px;

  .btn {
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  }
}


.el-popconfirm__action {
  display: flex;
  gap: .5rem;
  flex-direction: row-reverse;
  justify-content: center;

  .el-button + .el-button {
    margin-left: 0;
  }

  button {
    min-width: 50px;
  }
}


</style>
